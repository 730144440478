import React from "react";
import dateToText from "../components/datetext";

const DatePosted = ({ date }) =>
  <div className="flex-shrink-0 mt-2 sm:mt-4 flex items-center leading-5 text-gray-300">
    <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
    </svg>
    <span>
      <time dateTime={new Date(date)}>{dateToText(new Date(date))}</time>
    </span>
  </div>

export default DatePosted;