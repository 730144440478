import React from "react";

const ApplyNow = ({ link, category }) =>
  <div className="sm:flex-shrink-0">
    <div className="mt-4 sm:mt-0 w-full flex rounded-md shadow-sm">
      <a target="_blank" rel="noopener noreferrer" href={link} eventCategory={category} eventAction="Click" eventLabel={link}
        className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-semibold rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-red-700 transition ease-in-out duration-150">
        <span>Apply Now</span>
      </a>
    </div>
  </div>

export default ApplyNow;