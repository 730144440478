import React, { useState } from "react";
import PropTypes from 'prop-types';
import db from "./firestore";
import { collection, query, where, orderBy, getDocs } from "firebase/firestore/lite";
import Loading from "./loading";
import flag from "country-code-emoji";
import thirtyDaysAgoMillis from "../components/datehelper";
import countryName from "../components/countryName";
import { jobLink, companyLink } from "./links";
import Tags from "./tag"
import ApplyNow from "./applynow";
import DatePosted from "./dateposted";
import companies from "./companies";
import cms, { queryConstraintsWithWebsite } from "./cms/cms";

const Remoteness = ({ remote }) => {
  if (remote === "on_site") return <></>;
  else if (remote === "remote") {
    return <a href="/locations/remote" rel="noopener noreferrer" className="px-2 py-1 inline-flex leading-5 font-semibold rounded-full bg-teal-300 hover:bg-teal-200 text-teal-900">Remote
    </a>
  } else {
    return <span className="px-2 py-1 flex-shrink-0 leading-5 font-semibold rounded-full bg-teal-300 text-teal-900">
      Partially Remote
    </span>;
  }
}

const FeaturedCompanyJob = ({ entry }) =>
  <li className="-m-1 p-1 shadow-lg overflow-hidden sm:rounded-md mt-5 bg-gradient-to-br from-orange-400 to-red-600">
    <div className="bg-gradient-to-br from-gray-900 to-teal-800 sm:rounded-md overflow-hidden">
      <div className="px-4 pb-4 sm:px-6">
        <div className="flex-shrink-0 flex -ml-2 py-4 overflow-x-auto">
        <Remoteness remote={entry.remote} />
          <a
            href={companyLink(entry.featuredCompany)} eventCategory="Featured Company" eventAction="Click" eventLabel={entry.featuredCompany}
            className="inline-flex items-center flex-shrink-0 leading-5 px-2 py-1 font-semibold rounded-full bg-yellow-300 hover:bg-yellow-200 text-yellow-900 ml-2">
            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></svg>
            <span>Featured Company</span>
          </a>
          <a
            href={companyLink(entry.featuredCompany)} eventCategory="Community Sponsor" eventAction="Click" eventLabel={entry.featuredCompany}
            className="inline-flex items-center  flex-shrink-0 leading-5 px-2 py-1 font-semibold rounded-full bg-red-500 hover:bg-red-400 text-red-900 ml-2">
            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg>
            <span>Community Sponsor</span>
          </a>
        </div>
        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <h2 className="truncate pb-1 sm:mb-3 text-xl leading-5 font-bold text-red-500 hover:text-red-400">
              <a href={jobLink(entry.company, entry.title, entry.id)} eventCategory="Featured Job Title" eventAction="Click" eventLabel={entry.id}>
                {entry.title}
              </a>
            </h2>
            <div className="mt-2">
              <div className="sm:flex">
                <a
                  href={companyLink(entry.featuredCompany)} eventCategory="Company Title" eventAction="Click" eventLabel={entry.featuredCompany}
                  className="flex flex-shrink-0 items-center font-bold leading-5 text-gray-200">
                  {entry.image ? <img className="h-6 w-6 mr-2" src={entry.image} /> : <></>}
                  <h2>{entry.company}</h2>
                  <span><svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg></span>
                </a>
                {(entry.salaryFrom && entry.salaryTo) ?
                  <div className="sm:ml-4 mt-2 flex items-center leading-5 text-gray-300 sm:mt-0 sm:border-l sm:pl-4">
                    {entry.currency + entry.salaryFrom} - {entry.currency + entry.salaryTo}
                  </div> : <></>
                }
                <div className="sm:ml-4 mt-2 flex items-center leading-5 text-gray-300 sm:mt-0 sm:border-l sm:pl-4">
                  <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                  </svg>
                  <span>{entry.location}</span>
                </div>
                <div className="sm:mx-4 mt-2 flex items-center leading-5 text-gray-300 sm:mt-0 sm:border-l sm:pl-4">
                  <svg className="sm:hidden flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path></svg>
                  <span>{countryName(entry.country)}</span>
                  <span className="text-xl ml-3">{flag(entry.country)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden sm:block flex-shrink-0">
            <ApplyNow link={entry.link} category="Apply Now" />
          </div>
        </div>
        <div className="sm:flex sm:items-center justify-between">
          <Tags jobTags={entry.tags} />
          <DatePosted date={entry.date} />
        </div>
        <div className="sm:hidden block">
          <ApplyNow link={entry.link} category="Apply Now" />
        </div>
      </div>
    </div>
  </li>

const Job = ({ entry }) =>
  <li className="bg-gray-900 shadow-lg overflow-hidden sm:rounded-md mt-5">
    <div className="px-4 pb-4 sm:px-6">
      <div className="flex-shrink-0 flex -ml-2 py-4">
        <Remoteness remote={entry.remote} />
      </div>
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h2 className="truncate pb-1 sm:mb-3 text-xl leading-5 font-bold text-red-500 hover:text-red-400">
            <a href={jobLink(entry.company, entry.title, entry.id)} eventCategory="Featured Job Title" eventAction="Click" eventLabel={entry.id}>
              {entry.title}
            </a>
          </h2>
          <div className="mt-2">
            <div className="sm:flex">
              <div className="flex flex-shrink-0 items-center font-bold leading-5 text-gray-200">
                {entry.image ? <img className="h-6 w-6 mr-2" src={entry.image} /> : <></>}
                <h2>{entry.company}</h2>
              </div>
              {(entry.salaryFrom && entry.salaryTo) ?
                <div className="sm:ml-4 mt-2 flex items-center leading-5 text-gray-300 sm:mt-0 sm:border-l sm:pl-4">
                  {entry.currency + entry.salaryFrom} - {entry.currency + entry.salaryTo}
                </div> : <></>
              }
              <div className="sm:ml-4 mt-2 flex items-center leading-5 text-gray-300 sm:mt-0 sm:border-l sm:pl-4">
                <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                </svg>
                <span>{entry.location}</span>
              </div>
              <div className="sm:mx-4 mt-2 flex items-center leading-5 text-gray-300 sm:mt-0 sm:border-l sm:pl-4">
                <svg className="sm:hidden flex-shrink-0 mr-1.5 h-5 w-5 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path></svg>
                <span>{countryName(entry.country)}</span>
                <span className="text-xl ml-3">{flag(entry.country)}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:block flex-shrink-0">
          <ApplyNow link={entry.link} category="Apply Now" />
        </div>
      </div>
      <div className="sm:flex sm:items-center justify-between">
        <Tags jobTags={entry.tags} />
        <DatePosted date={entry.date} />
      </div>
      <div className="sm:hidden block">
        <ApplyNow link={entry.link} category="Apply Now" />
      </div>
    </div>
  </li >

const Entries = ({ entries }) =>
  (entries.length > 0) ?
    <ul> {
      entries.map(entry => (entry.featuredCompany && companies[entry.featuredCompany]) ? <FeaturedCompanyJob key={entry.id} entry={entry} /> : <Job key={entry.id} entry={entry} />)
    }
    </ul > : <div className="bg-gray-900 shadow-lg overflow-hidden sm:rounded-md mt-5 px-4">
      <h2 className="pt-6 text-center text-2xl leading-5 font-bold text-red-500">
        Create a Job Post
      </h2>
      <p className="text-center mt-4 font-bold text-lg leading-5 text-gray-200">Hire {cms.keyword} Developers by posting a job</p>
      <div>
        <div className="my-6 flex justify-center rounded-md shadow-sm">
          <a href="/post-job" eventCategory="Button" eventAction="Click" eventLabel="Post Featured Job"
            className="sm:w-auto w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-semibold rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-red-700 transition ease-in-out duration-150">
            <span>Post Job</span>
          </a>
        </div>
      </div>
    </div>

Entries.propTypes = {
  entries: PropTypes.array
};

const composeQuery = async (remote, countries, tag, company, featuredCompanies) => {

  const queryConstraints = Array(where("date", ">=", thirtyDaysAgoMillis()), orderBy("date", "desc"));

  if (remote) queryConstraints.push(where("remote", "==", remote));
  if (countries) queryConstraints.push(where("country", "in", countries));
  if (tag) queryConstraints.push(where("tags", "array-contains", tag));
  if (company) queryConstraints.push(where("featuredCompany", "==", company));
  if (featuredCompanies) queryConstraints.push(orderBy("featuredCompany", "desc"));

  const constraints = queryConstraintsWithWebsite(queryConstraints, tag)

  const snapshot = await getDocs(query(collection(db, "featured_jobs"), ...constraints));

  return snapshot.docs.map(doc => {
    return { ...{ id: doc.id }, ...doc.data() };
  });
}

const FeaturedJobs = ({ countries, remote, localizedTitle, tag, company, featuredCompanies }) => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    composeQuery(remote, countries, tag, company, featuredCompanies)
      .then(jobs => {
        setJobs(jobs);
        setLoading(false);
      });
  }, [countries, remote, tag, company, featuredCompanies]);

  return <div>
    <h1 className="text-center sm:py-2 text-3xl sm:text-4xl tracking-tight leading-10 font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-600">
      {localizedTitle ?
        <span>
          {localizedTitle} {countries ? <span className="text-black">{countries.map(flag)}</span> : <></>}
        </span> : <span>Featured {cms.keyword} Jobs</span>}
    </h1>
    {loading ? <div className="mt-5"><Loading size={10} /></div> : <Entries entries={jobs} />}
  </div>

};

FeaturedJobs.propTypes = {
  countries: PropTypes.array,
  remote: PropTypes.string,
  localizedTitle: PropTypes.string,
  tag: PropTypes.string
}

export default FeaturedJobs;