import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";

const firebaseConfig = {
    apiKey: "AIzaSyAsTSroffI7v7F3uyPyddUxgChuXwtQn3w",
    authDomain: "scalajobs-56919.firebaseapp.com",
    databaseURL: "https://scalajobs-56919.firebaseio.com",
    projectId: "scalajobs-56919",
    storageBucket: "scalajobs-56919.appspot.com",
    messagingSenderId: "1023357450516",
    appId: "1:1023357450516:web:aa427c32b11166e7752a01",
    measurementId: "G-NNMLHW1FNK"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;