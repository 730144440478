const oneDay = 24 * 60 * 60 * 1000;
const currentDate = new Date();

const dateToText = date => {
  const diff = Math.round(Math.abs((currentDate - date) / oneDay));
  if (diff < 1) return "Today";
  else if (diff < 2) return "Yesterday";
  else return `${diff} days ago`
}


export default dateToText